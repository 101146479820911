import * as React from "react";
import './App.css';
import gradientBG from'./assets/gradientBG.jpg';
import mockupPortrait from './assets/mockupPortrait.png';



function App() {
    return (
    <div className="flex flex-col bg-white">
        <div className="flex overflow-hidden flex-col w-full bg-gray-900 max-md:max-w-full">
            <div className="flex flex-wrap gap-5 justify-between items-center py-5 pr-4 pl-5 w-full bg-gray-900 max-md:pl-5 max-md:max-w-full">
                <div className="flex gap-1.5 items-center self-stretch my-auto text-xl font-bold leading-none text-white whitespace-nowrap">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/26ba8467df0400afb626ec36429fa39b92c65717d8069625270a247461a23e68?apiKey=5329980c9c59416ca6718ce06cbbd0e1&&apiKey=5329980c9c59416ca6718ce06cbbd0e1"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[0.87] w-[26px]"
                    alt=""
                />
                <div className="self-stretch my-auto">Compliancy</div>
                </div>
                <div className="flex gap-3 items-center self-stretch my-auto text-sm font-medium leading-none text-white w-[201px]">
                    <a className="gap-1.5 self-stretch px-3.5 py-2.5 my-auto border border-[#fff] rounded-[100px] hover:bg-white hover:text-gray-900" href="https://calendly.com/reed-compliancy/30min" target="_blank">
                        Meet With Us
                    </a>
                    <a className="gap-1.5 self-stretch px-3.5 py-2.5 my-auto whitespace-nowrap bg-indigo-500 rounded-[100px] ring-1 ring-black hover:ring-inset" href="https://app.withcompliancy.com">
                        Forms
                    </a>
                </div>
            </div>
        </div>
        <div className="flex items-start w-full max-md:max-w-full">
        <div className="flex overflow-hidden flex-col flex-1 shrink p-10 w-full basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full">
            <div className="flex w-full min-h-[10px] max-md:max-w-full" />
            <div className="flex flex-col items-center self-center mt-40 max-md:mt-10 max-md:max-w-full">
                <div className="flex flex-col justify-center items-center text-xl max-md:max-w-full">
                    <div className="flex gap-2.5 justify-center items-center px-5 py-1.5 text-base font-bold text-green-700 bg-emerald-50 rounded-[100px]">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/a2253159c99f83143f8bf0044856e111d2f5fcabbf2b4742566f0298b86284a6?apiKey=5329980c9c59416ca6718ce06cbbd0e1&&apiKey=5329980c9c59416ca6718ce06cbbd0e1"
                        className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
                        alt=""
                    />
                    <div className="self-stretch my-auto">
                        Software Enabled Permit Expediting
                    </div>
                    </div>
                    <div className="mt-5 text-6xl font-extrabold text-black max-md:max-w-full max-md:text-4xl">
                    Roofing Permits in South Florida
                    </div>
                    <div className="mt-5 text-center text-black max-md:max-w-full">
                    Let us take care of your permits and engineering, so you can get
                    back to roofing.
                    </div>
                    <div className="flex gap-2.5 items-center mt-5 font-medium max-md:max-w-full">
                    <a className="gap-1.5 self-stretch px-5 py-4 my-auto text-white bg-indigo-500 rounded-[100px] border-2 hover:border-[#5D5FEF]" href="https://calendly.com/reed-compliancy/30min" target="_blank">
                        Book a Meeting
                    </a>
                    <a className="gap-1.5 self-stretch px-5 py-4 my-auto text-black rounded-[100px] ring-1 ring-black hover:ring-inset" href="https://app.withcompliancy.com/">
                        Get The Right Forms
                    </a>
                    </div>
                </div>
                <div className="flex overflow-hidden flex-wrap gap-5 justify-center items-center mt-24 bg-white max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col justify-center self-stretch px-5 pt-5 pb-10 my-auto bg-white rounded min-w-[240px] w-[600px] max-md:max-w-full ring-1 ring-indigo-500 ring-inset">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d80bd2f8ed1aa7ee6b19a087430c14846adaf38ea421adca4a5d1bb8945a4656?apiKey=5329980c9c59416ca6718ce06cbbd0e1&&apiKey=5329980c9c59416ca6718ce06cbbd0e1"
                            className="object-contain w-10 aspect-square"
                            alt=""
                        />
                        <div className="mt-5 text-xl font-bold text-slate-700">
                            Permitting
                        </div>
                        <div className="mt-5 text-base text-slate-400 max-md:max-w-full">
                            We help roofers pull permits faster and with more accuracy, so
                            you can worry about getting more jobs
                        </div>
                    </div>
                    <div className="flex flex-col justify-center self-stretch px-5 pt-5 pb-10 my-auto bg-white rounded min-w-[240px] w-[600px] max-md:max-w-full ring-1 ring-indigo-500 ring-inset">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1846772cc904e2c5aa63588fbc104f84b8840c37813203080a357094f6223c98?apiKey=5329980c9c59416ca6718ce06cbbd0e1&&apiKey=5329980c9c59416ca6718ce06cbbd0e1"
                            className="object-contain w-10 aspect-square"
                            alt=""
                        />
                        <div className="mt-5 text-xl font-bold text-slate-700">
                            Engineering
                        </div>
                        <div className="mt-5 text-base text-slate-400 max-md:max-w-full">
                            We work with engineers so you don’t have to worry about
                            scheduling tests and filling out the right documents
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap gap-5 px-24 mt-40 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-1 shrink gap-2.5 justify-center items-center h-full basis-0 min-w-[240px] max-md:max-w-full">
                <img
                loading="lazy"
                srcSet={mockupPortrait}
                className="object-contain self-stretch my-auto aspect-[0.52] min-w-[240px] w-[398px]"
                alt=""
                />
            </div>
            <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                <div className="flex flex-col w-full text-black max-md:max-w-full">
                <div className="text-5xl font-bold max-md:max-w-full max-md:text-4xl">
                    Use{" "}
                    <a className="text-indigo-500 underline" href="https://app.withcompliancy.com">Our Website</a>{" "}
                    To Always Have The Right Forms
                </div>
                <div className="mt-5 text-xl leading-8 max-md:max-w-full">
                    Once you provide us with the forms, we’ll complete the
                    documents, ensure their correctness, add additional required
                    documents, perform engineering, and submit them to the
                    appropriate municipality.
                </div>
                </div>
                <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                <div className="flex flex-col max-md:max-w-full">
                    <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="flex flex-wrap gap-5 items-center w-full max-md:max-w-full">
                        <div className="flex gap-2.5 justify-center items-center self-stretch p-5 my-auto w-[70px]">
                        <div className="flex self-stretch my-auto bg-sky-200 rounded-full h-[30px] min-h-[30px] w-[30px]" />
                        </div>
                        <div className="flex-1 shrink self-stretch my-auto text-3xl font-bold text-black basis-10 min-w-[240px] max-md:max-w-full">
                        Faster Permits
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-5 items-center w-full text-xl leading-8 text-black max-md:max-w-full">
                        <div className="flex shrink-0 gap-2.5 self-stretch my-auto h-[70px] w-[70px]" />
                        <div className="flex-1 shrink self-stretch my-auto min-w-[240px] max-md:max-w-full">
                        We always submit permits in the same day or next day,
                        engineering pending.
                        </div>
                    </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-wrap gap-5 items-center w-full max-md:max-w-full">
                    <div className="flex gap-2.5 justify-center items-center self-stretch p-5 my-auto w-[70px]">
                        <div className="flex self-stretch my-auto bg-sky-200 rounded-full h-[30px] min-h-[30px] w-[30px]" />
                    </div>
                    <div className="flex-1 shrink self-stretch my-auto text-3xl font-bold text-black basis-10 min-w-[240px] max-md:max-w-full">
                        Less Rejection
                    </div>
                    </div>
                    <div className="flex flex-wrap gap-5 items-center w-full text-xl leading-8 text-black max-md:max-w-full">
                    <div className="flex shrink-0 gap-2.5 self-stretch my-auto h-[70px] w-[70px]" />
                    <div className="flex-1 shrink self-stretch my-auto min-w-[240px] max-md:max-w-full">
                        Using software we’ve built for ourselves, our permits are
                        more accurate and are more likely to be approved.
                    </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-wrap gap-5 items-center w-full max-md:max-w-full">
                    <div className="flex gap-2.5 justify-center items-center self-stretch p-5 my-auto w-[70px]">
                        <div className="flex self-stretch my-auto bg-sky-200 rounded-full h-[30px] min-h-[30px] w-[30px]" />
                    </div>
                    <div className="flex-1 shrink self-stretch my-auto text-3xl font-bold text-black basis-10 min-w-[240px] max-md:max-w-full">
                        Less Headache
                    </div>
                    </div>
                    <div className="flex flex-wrap gap-5 items-center w-full text-xl leading-8 text-black max-md:max-w-full">
                    <div className="flex shrink-0 gap-2.5 self-stretch my-auto h-[70px] w-[70px]" />
                    <div className="flex-1 shrink self-stretch my-auto min-w-[240px] max-md:max-w-full">
                        You don’t have to worry about permits, so you can get back
                        to doing things that brings your company more revenue.
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="flex flex-col items-center self-center mt-40 max-w-full text-black w-[1000px] max-md:mt-10">
                <div className="flex flex-col w-full">
                    <div className="self-start text-5xl font-bold text-center max-md:text-4xl">
                        Start Growing With Us
                    </div>
                    <div className="mt-5 text-xl leading-8 max-md:max-w-full">
                        We keep track of all the constantly changing materials as well
                        as the changing municipal forms, so we won’t submit an out of
                        date material. We’re becoming experts of south Florida building
                        code, and we’d love to work with y’all on your next project,
                        give us a call: 972 730-2310
                    </div>
                </div>
            </div>
            <div className="flex flex-col mt-40 w-full max-md:mt-10 max-md:max-w-full">
                <div className="flex overflow-hidden gap-5 justify-center items-center px-10 w-full font-bold bg-white min-h-[204px] max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-wrap flex-1 shrink gap-5 items-center self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                        <div className="flex flex-col grow shrink justify-center self-stretch px-5 pt-5 pb-10 my-auto bg-white rounded min-h-[204px] min-w-[240px] w-[675px] max-md:max-w-full ring-1 ring-indigo-500 ring-inset">
                            <div className="text-4xl text-indigo-500">$200</div>
                            <div className="mt-5 text-xl text-slate-700">
                            Residential Permits
                            </div>
                            <div className="mt-5 text-base text-slate-400 max-md:max-w-full">
                            All we need from you are the signed forms, and then from
                            here, Compliancy will process all of the paperwork,
                            engineering, and submission.
                            </div>
                        </div>
                        <div className="flex flex-col grow shrink justify-center self-stretch px-5 pt-5 pb-10 my-auto bg-white rounded min-h-[204px] min-w-[240px] w-[675px] max-md:max-w-full ring-1 ring-indigo-500 ring-inset">
                            <div className="text-4xl text-indigo-500">$500</div>
                            <div className="mt-5 text-xl text-slate-700">
                            Commercial Permits
                            </div>
                            <div className="mt-5 text-base text-slate-400 max-md:max-w-full">
                            Compliancy will always make sure you are up to date for all
                            of your licensing before the permits is submitted. We’ll
                            also take care of the notice of commencement and Asbestos
                            requirements.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex overflow-hidden flex-col justify-center px-10 py-12 mt-5 w-full font-semibold rounded max-md:px-5 max-md:max-w-full">
                    <div className="flex relative flex-col flex-wrap gap-5 px-32 py-16 w-full rounded-xl min-h-[198px] max-md:px-5 max-md:max-w-full">
                    <img
                        loading="lazy"
                        srcSet={gradientBG}
                        className="object-cover absolute inset-0 size-full"
                        alt=""
                    />
                    <div className="relative flex-1 shrink gap-2.5 self-stretch px-5 h-full text-3xl text-indigo-500 min-w-[240px] max-md:max-w-full">
                        Schedule a meeting with our founder to get the most out of our
                        services.
                    </div>
                    <div className="flex relative flex-1 shrink gap-2.5 justify-center items-center p-2.5 h-full text-xl text-center text-white basis-5 min-w-[240px] max-md:max-w-full">
                        <a className="flex gap-2.5 justify-center items-center self-stretch my-auto" href="https://calendly.com/reed-compliancy/30min" target="_blank">
                            <div className="gap-2.5 self-stretch px-5 py-3 my-auto bg-indigo-500 rounded">
                                Book A Meeting
                            </div>
                        </a>
                    </div>
                    </div>
                </div>
            </div>
            <div className="flex overflow-hidden flex-wrap gap-1 items-start px-3 py-4 mt-40 w-full text-sm text-gray-900 max-md:mt-10 max-md:max-w-full border-t border-black">
                <div className="flex-1 shrink basis-0 max-md:max-w-full">
                    RGBZ, Inc. <br/> 3311 Oak Lawn Ave, Dallas TX. 75219
                </div>
                <div className="flex-1 shrink text-right basis-0 max-md:max-w-full">
                    Reed Graff | 972 730-2310
                    Reed@withcompliancy.com
                </div>
            </div>
        </div>
        </div>
    </div>
    );
}


export default App;